<template>
    <div class="main-page">
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div class="">
                            <div >
                                <template v-if="!loading">
                                    <div class="grid">
                                        <div class="col-12">
                                            <form ref="observer"  tag="form" @submit.prevent="startRecordUpdate()">
                                                <!--[form-content-start]-->
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Name 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlname" v-model.trim="formData.name"  label="Name" type="text" placeholder="Enter Name"      
                                                                class=" w-full" :class="getErrorClass('name')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Username 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlusername" v-model.trim="formData.username"  label="Username" type="text" placeholder="Enter Username"      
                                                                class=" w-full" :class="getErrorClass('username')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Photo 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <div class="mb-3">
                                                                    <Uploader :class="getErrorClass('photo')" :auto="true" :fileLimit="1" :maxFileSize="3000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" style="width:100%" label="Choose files or drop files here" :url="$utils.setApiPath('fileuploader/upload/photo')" v-model="formData.photo"></Uploader>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--[form-content-end]-->
                                                <div v-if="showSubmitButton" class="text-center my-3">
                                                    <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                </div>
                                            </form>
                                            <slot :submit="submit" :saving="saving"></slot>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
	import { PageMixin } from "../../mixins/page.js";
	import { EditPageMixin } from "../../mixins/editpage.js";
	import { mapActions, mapGetters, mapState } from "vuex";
	import useVuelidate from '@vuelidate/core';
	import { required, email, sameAs, minLength,maxLength,minValue,maxValue,numeric,integer,decimal,ipAddress } from '@vuelidate/validators';
	export default {
		setup () {
			return { v$: useVuelidate() }
		},
		name: 'accounteditUsersPage',
		components: {
		},
		mixins: [PageMixin, EditPageMixin ],
		props: {
			pageName: {
				type: String,
				default: 'users',
			},
			idName: {
				type: String,
				default: 'user_id',
			},
			routeName: {
				type: String,
				default: 'usersaccountedit',
			},
			pagePath: {
				type : String,
				default : 'account/edit',
			},
			apiPath: {
				type: String,
				default: 'account/edit',
			},
		},
		data() {
            return {
				formData: {
					name: "", username: "", photo: "", 
				},
				submitted: false,
        	}
		},
		computed: {
			pageTitle:{
				get: function () {
					return "My Account"
				}
			},
			currentRecord: {
				get: function () {
					return this.$store.getters["users/currentRecord"];
				},
				set: function (value) {
					this.$store.commit("users/setCurrentRecord", value);
				},
			},
		},
		validations() {
			let formData =  {
				name: {  },username: {  },photo: {  },
			}
			return {formData}
		},
		methods: {
			...mapActions('users', ['updateRecord', 'fetchRecord']),
			async startRecordUpdate(){
				this.submitted = true;
				const isFormValid = !this.v$.$invalid;
				if(!isFormValid){
					this.flashMsg("Please complete the form", "Form is invalid", "error");
					return;
				}
				this.saving = true;
				let id = this.id;
				let url = this.apiUrl;
				let payload = this.normalizedFormData();
				let data = { id, url, payload }
				this.updateRecord(data).then(
					(response) => {
						this.saving = false;
						this.flashMsg(this.msgAfterUpdate);
						this.resetForm();
						this.closeDialogs();// close page dialog that if opened
						location.reload();
					},
					(response) => {
						this.saving = false;
						this.showPageRequestError(response);
					}
				);
			},
			updateFormFields: function(){
				//update form fields value after load from api
				//e.g convert fieldvalue (value,value2,value2) to array 
            },
			resetForm (){
				//reset form fields value
				this.formData = {name: "", username: "", photo: "", };
				//raise event to reset other custom form components
				//this.$EventBus.$emit("resetForm");
			},
			getErrorClass(field){
			   if(this.v$.formData[field].$invalid && this.submitted){
				    return "p-invalid";
			   }
               return "";
            },
		},
		watch: {
		},
		async mounted() {
		},
		async created() {
		},
	};
</script>
<style scoped>
</style>
